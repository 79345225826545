export const MenuItem=[
    {
        item:'About',
        url:'AboutID'
    },
    {
        item:'Problem',
        url:'ProblemID'
    },
    {
        item:'Solution',
        url:'SolutionID'
    }, {
        item:'Resources',
        url:'ResourcesID'
    },
    {
        item:'Team',
        url:'TeamID'
    },
    {
        item:'Partners',
        url:'PartnersID'
    },
    {
        item:'Contact',
        url:'ContactID'
    }
]