import React from 'react'
import NavBar from '../pages/NavBar'
import About from './About'
import Banner from './Banner'
import Contact from './Contact'
import Partners from './Partners'
import Problem from './Problem'
import Resource from './Resource'
import Solution from './Solution'
import Team from './Team'
function Home() {
    return (
        <div>
            <NavBar />
            <Banner />
            <About />
            <Problem/>
            <Solution />
            <Resource />
            <Team/>
            <Partners/>
            <Contact />
        </div>
    )
}

export default Home
