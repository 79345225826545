import React from 'react'
import '../css/Solution.css'
import {solutiondata, solutionCard} from '../data/solutiondata'
//import ReactPlayer from 'react-player'

function Solution() {
    const {MainContent}=solutiondata[0]
    return (
        <div className="SolutionContainer" id="SolutionID">
            <div className="SvgContainers">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="white"></path></svg>
            </div>
            <div className=" Container SolutionMain">
                <div className='Solution_Title'><h2>Solution</h2></div>
                <div className='Solution_sessions'>
                    <div className="SolutionPhoto">
                        
                             <iframe   src="https://www.youtube-nocookie.com/embed/s9CdDnCPWzg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        
                    </div>
                    <div className="SolutionText">
                        <p className="MainContentSolution">{MainContent}</p>
                    </div>
                </div>
                <div className="SolutionSubPhoto">
                    {
                        solutionCard.map((el, index)=>{
                            const {Icon, Content}=el;
                            return(
                                <div className="SSubBox" key={index}>
                                    <i className={Icon}></i>
                                    <span>{Content}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Solution
