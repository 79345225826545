import React from 'react'
import '../css/PrivacyPolicy.css'

function PrivacyPolicy() {
    return (
        <div className="PrivacyPolicyContainer" id="PrivacyPolicyID">
            <div className="SvgContainers">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
            </div>
            <div className=" Container PrivacyPolicyMain">
                <div className='H1_PrivacyPolicy'><h1>BioMassters Privacy Policy</h1></div>
                <div className="PrivacyPolicyTitle"><h2>Amategeko Agenga Amakuru y’Ibanga muri BioMassters </h2></div>
                <div className='PrivacyPolicy_sessions'>
                    <div className="PrivacyPolicyText">
                        <div className='MainPrivacyPolicyText'>
                            <p>BioMassters strives to provide the best products and services to our customers. In order to do so we need to collect, process, and store personal data of our customers, newsletter subscribers, and marketing participants. The personal data we collect and process will be securely saved and managed in compliance with Law No. 058/2021 of 13 October 2021 relating to the Protection of Personal Data and Privacy. With this form, we inform you which data we collect, why it is collected, how this data is used, how long this data is stored, and you are informed about your rights regarding your personal data. We will only use your personal data for the purposes stated here within.<br /><br />Please note that this privacy policy consent form is subject to change; the most updated version will always be available on our website (www.biomassters.co).<br /><br /></p>
                            <p>BioMassters  ishishikajwe no gutanga ibicuruzwa byiza na serivisi nziza kubakiliya bacu, abaf. Muri urwo rwego dukeneye gukusanya no kubika amakuru bwite y’abakiliya bacu, abafatabuguzi b'inyandiko y’amakuru, n'abadufasha kwamamaza. Amakuru bwite dukusanya n’uburyo abikwamo butekanye kandi bikurikije itegeko No.058/2021 ryo ku wa 13 Ukwakira 2021 bisanishwa n’uburinzi bw’amakuru bwite n’iyi fishi. Tubamenyesha ko amakuru dukusanya, impamvu tuyakusanya n’uko akoreshwa n’igihe yamara abitse  kandi ukamenyeshwa ibijyanye n’uburenganzira bwawe ku byerekeye amakuru yawe bwite . Amakuru yawe bwite tuyakoresha kuri izi mpamvu zonyine zikurikira.<br /><br />Menyako iyi ari ifishi yo gutanga uburenganzira ku makuru yawe bwite ishobora guhindurwa; izajya iba igenzwe izajya ishyirwa ku rubuga rwacu: (www.biomassters.co). <br /><br /></p>

                            <h2>1. The personal data we use and why</h2>
                            <h3>1. Amakuru bwite dukoresha n’impamvu</h3>

                            <p>We collect and process your personal data that is necessary for the provision of our pellet and cookstove services, such as product improvement, after-sales care, stove repairs, and rewards programs, among others. Apart from select data processing partners that we work with, your data will not be shared with third parties, unless we are legally obliged to do so. Our data processing partners include Google Ireland, Heroku (Salesforce), Oltranz Ltd, EDCL, SustainCERT, FLOCERT, FairClimateFund, CARE International UK, Cloudinary, Mimi Moto, and TransUnion. For deliveries, your name, address, and phone number are also shared with the delivery person. Our data processing partners are subject to change. Customers will be informed about any changes. <br /><br />We store your personal data for the purposes described below. Please note that we only collect and process the personal data below in case we are offering the associated services to you.<br /><br /></p>
                            <p>Dukusanya amakuru yawe bwite mu buryo bukenewe kugirango tubahe serivisi  za pareti n’amashyiga nko kongera ubwiza bw’ibicuruzwa, kwita ku bakiliya nyuma yo kugura, gusana amashyiga na gahunda z’ibihembo, n’ibindi. Havuyemo abadufasha gutunganya amakuru, nta bandi bantu twasangiza amakuru keretse igihe twabitegetswe ariko mu buryo bwubahirije amategeko. Amakuru yacu atunganywa n’abafatanya bikorwa bacu harimo Google Ireland, Heroku (sales force), Oltranz Ltd, EDCL, SustainCERT, FLOCERT, FairClimateFund, CARE International UK, Cloudinary, Mimi moto, TransUnion. Kugutwariza, izina ryawe, aderesi, numero ya terefone tubiha umuntu ugiye kugutwariza. Mu makuru yacu abafatanyabikorwa batunganya iyo hari igihindurwa abakiliya bamenyeshwa buri mpinduka<br /><br />Tubika amakuru yawe y’ibanga kubw’impamvu zasobanuwe hasi. Menyako dufata amakuru yawe bwite kugirango tuguhe serivisi zijyanye nayo.<br /><br /></p>

                            <h3>1.1. Sales<br />1.1 Kugurisha<br /><br /></h3>
        
                            <h3>1.1.1. Stove cash</h3>
                            <p>We may collect and use the following personal data in order to provide our stove donation services: name, signature, NID number, household size, stove use purpose, phone number, address, cooking fuels used before becoming a BioMassters Ltd. customer, date of registration, contract status, mobile money account phone number, payment details, and stove serial number.<br /><br /></p>

                            <h3>1.1.1. Ishyiga ryishyuriwe rimwe byuzuye</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru yawe bwite akurikira kugirango tubahe serivisi: izina, umukono, nimero y’indagamuntu, umubare w’abantu baba mu rugo, icyo uzakoresha ishyiga, nimero ya telefone, aderesi, ishyiga wakoreshaga mbere yo kuba umukiliya wa Biomassters Ltd., itariki wabaye umukiliya, imiterere y’amasezerano, nimero ya telefone yawe iri muri momo, amakuru y’ubwishyu, umubare bwite w’ishyiga.<br /><br /></p>

                            <h3>1.1.2 Stove donations</h3>
                            <p>We may collect and use the following personal data in order to provide our stove donation services: name, signature, NID number, photo of national ID, household size, stove use purpose, phone number, address, cooking fuels used before becoming a BioMassters Ltd. customer, date of registration, contract status, mobile money account phone number, payment details, and stove serial number.<br /><br /></p>
                                  
                            <h3>1.1.2 Inkunga y’amashyiga</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru yawe bwite akurikira kugirango tubahe serivisi: izina, umukono, nimero n’ifoto y’indagamuntu, umubare w’abantu baba mu rugo, icyo uzakoresha ishyiga, nimero ya telefone, aderesi, ishyiga wakoreshaga mbere yo kuba umukiliya wa Biomassters Ltd., itariki wabaye umukiliya, imiterere y’amasezerano, nimero ya telefone yawe iri muri momo, amakuru y’ubwishyu, umubare bwite w’ishyiga.<br /><br /></p>
                            
                            <h3>1.1.3 Stove Pay-As-You-Go</h3>
                            <p>We may collect and use the following personal data in order to provide our stove Pay-As-You-Go (PAYGO) services: name, signature, NID number, photo of national ID, household size, stove use purpose, phone number, address, cooking fuels used before becoming a BioMassters Ltd. customer,  date of registration, contract status, mobile money account phone number, PAYGO repayment details, payment details, stove serial number, location of the stove, when the stove is in use, and how much time is spent using the stove.<br /><br /></p>
                                   
                             <h3>1.1.3 Ishyiga ryishyurwa mu byiciro</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru yawe bwite akurikira kugirango tubahe serivisi: izina, umukono, nimero n’ifoto y’indagamuntu, umubare w’abantu baba mu rugo, icyo uzakoresha ishyiga, nimero ya telefone, aderesi, ishyiga wakoreshaga mbere yo kuba umukiliya wa Biomassters Ltd., itariki wabaye umukiliya, imiterere y’amasezerano, nimero ya telefone yawe iri muri momo, amakuru arambuye yo kwishyura mu byiciro, amakuru arambuye yo kwishyura muri rusange, umubare bwite w’ishyiga, aho ishyiga riherereye, igihe rikoreshwa n’igihe rimara riri gukoreshwa.<br /><br /></p>
                            
                            <h3>1.1.4 Pellet  sales</h3>
                            <p>We may collect and use the following personal data in order to provide our pellet sale services: name, customer ID, phone number, mobile money account phone number, payment details, and PAYGO repayment details.<br /><br /></p>
                                     
                            <h3>1.4 .1   Icuruzwa rya pareti</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru yawe bwite akurikira mu rwego rwo gutanga serivisi zacu zo gucuruza pareti: izina, nimero y’umukiliya, nimero ya telefone, nimero ya telefone iri muri momo, amakuru y’ubwishyu n’uko wishyura ishyiga mu byiciro.<br /><br /></p>
                            
                            <h3>1.1.5 Spare parts and accessories sales</h3>
                            <p>We may collect and use the following personal data in order to provide our spare parts and accessories sales services: name, customer ID, phone number, mobile money account phone number, and payment details.<br /><br /></p>
                                          
                            <h3>1.1.5. Igurisha ry’ibikoresho byo guhindura n’ibindi bikoresho by’ishyiga</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru bwite akurikira mu rwego rwo gutanga serivisi zo kugurisha ibikoresho byo guhindura n’ibindi bikoresho by’ishyiga: izina, nimero y’umukiliya, nimero ya telefone, nimero ya momo  n’amakuru arambuye ku bwishyu bw’ishyiga.<br /><br /></p>
                            
                            <h3>1.1.6 Delivery services</h3>
                            <p>We may collect and use the following personal data in order to provide our delivery services: name, customer ID, phone number, mobile money account phone number, and address.<br /><br /></p>
                                          
                            <h3>1.1.6 Serivisi zo gutwariza abakiliya</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru bwite akurikira mu rwego rwo gutanga serivisi zo gutwariza abakiliya amashyiga na pareti: izina, nimero y’umukiliya, nimero ya telefone, nimero ya momo, aderesi.<br /><br /></p>
                            
                            <h3>1.2. Improvement of our services<br />1.2 Guteza imbere serivisi zacu<br /><br /></h3>
                            
                            <h3>1.2.1 Repairs</h3>
                            <p>We collect and use the following personal data in order to provide our warranty and repair services: customer ID, use purpose, stove serial number, date of initial purchase, date of defect, stove defect, nature of repair, date of repair, and location of repair.<br /><br /></p>
                             
                            <h3>1.2.1 Gusana</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru bwite akurikira mu rwego rwo gutanga serivisi za garanti n’izo gusana: nimero y’umukiliya, icyo akoresha ishyiga, umubare bwite w’ishyiga, itariki wafasheho ishyiga, itariki ryagiriye ikibazo, ikibazo ishyiga ryagize, uko riri bukorwe, itariki rikorewe n’aho ryakorewe.<br /><br /></p>
                            
                            <h3>1.2.2 Customer service up to three months after initial purchase</h3>
                            <p>We collect and use the following personal data in order to provide our customer service services up to three months after initial purchase: name, customer ID, phone number, date of initial purchase, PAYGO repayment details, and pellet purchase details.<br /><br /></p>
                            
                            <h3>1.2.2  Kwita kubakiriya mu mezi atatu ya mbere baguze ishyiga</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru bwite akurikira mu rwego rwo gutanga serivisi zacu zo kwita kubakiliya mu mezi atatu ya mbere baguze ishyiga: izina, nimero y’umukiliya,- nimero ya telefone, itariki wafatiyeho ishyiga, amakuru arambuye y’uko wishyura mu byiciro n’amakuru arambuye y’uko ugura pareti.<br /><br /></p>
                            
                             <h3>1.2.3 Call center</h3>
                            <p>We collect and use the following personal data in order to provide our customer service services: name, customer ID, date of contacting customer service, customer service query, 
                            and issue resolution date.<br /><br /></p>
                                  
                            <h3>1.2.3 Umurongo ufasha abakiliya</h3> 
                            <p>Dukusanya kandi tukanakoresha amakuru bwite murwego rwo gutanga serivisi zacu zo kwita ku bakiliya: izina, nimero y’umukiliya, itariki yahamagaye abashinzwe abakiliya, ikibazo yarafite ndetse n’igihe icyo kibazo cyakemukiye.<br /><br /></p>
                            
                            <h3>1.2.4 Business model improvements</h3>
                            <p>We may collect and use the following personal data in order to improve our business model, this data is always processed anonymously: gender, usage data, use purpose, customer service query, PAYGO repayment information, and pellet purchase details.<br /><br /></p>
                                   
                            <h3>1.2.4  Iterambere ryimiterere y’ubucuruzi</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru yawe bwite akurikira murwego rwo kongera imiterere y’ubucuruzi ariko aya makuru iteka atunganywa muburyo mu bwibanga: igitsina, amakuru y’uko ukoresha ishyiga, icyo ukoresha ishyiga, ibibazo bijyanye na serivisi, amakuru y’uko wishyura mu byiciro n’amakuru yuko ugura pareti.<br /><br /></p>
                            
                            
                            <h3>1.2.5 Stove and accessories improvements</h3>
                            <p>We may collect and use the following personal data in order to improve our stove and accessories; this data is always processed anonymously: date of initial purchase, use purpose, date of repair, issue with the stove and parts fixed, pellet purchase details, and suggestions by customers for improvements.<br /><br /></p>
                            
                            <h3>1.2.5 Gukongerera ubushobozi amashyiga n’ibikoresho byayo</h3>
                            <p>Dukusanya kandi tukanakoresha amakuru bwite akurikira mu rwego rwo kongerera ubushobozi amashyiga yacu n’ibikoresho byayo, aya makuru atunganywa muburyo bw’ibanga: itariki wafasheho ishyiga, icyo ishyiga rikoreshwa, itariki ryasaniwe, ikibazo ishyiga ryagize n’ibice byari byangiritse,amakuru y’uko ugura pareti n’icyo abakiliya babitekerezaho.<br /><br /></p>
                            
                            <h3>1.3. Carbon Reward Program<br />1.3  Gahunda y’ibihembo byo kugabanya ibyuka byangiza ikirere<br /><br /></h3>
                            
                            <p>We collect the following personal data in order to offer our carbon reward program in the form of cashback services and its associated audits: name, signature, NID number, household size, stove use purpose, phone number, address, customer ID, cooking fuels used before becoming a BioMassters Ltd. customer, date of registration, pellet purchase details, mobile money account phone number, payment details, stove serial number, gender, type of contract with BioMassters, and how much time is spent using the stove.<br /><br /></p>
                                      
                            <p>Dukusanya amakuru akurikira murwego rwa gahunda yo gutanga ibihembo byo kugabanya ibyuka byangiza ikirere muburyo bw’ amafaranga na gahunda z’igenzura: izina, umukono, nimero y’indangamuntu, umubare w’abantu baba mu rugo, icyo uzakoresha ishyiga, nimero ya terefone, nimero y’umukiliya, ibicanwa wakoreshaga mbere yo kuba umukiliya wa Biomassters Ltd., amakuru arambuye y’uko ugura pareti, nimero ya momo, umubare bwite w’ishyiga, igitsina, ubwoko bw’amasezerano ufitanye na Biomassters, n’igihe umara ukoresha ishyiga.<br /><br /></p>
                            
                            <h3>1.4. Marketing and fundraising<br />1.4. Kwamamaza no gukusanya inkunga <br /><br /></h3>
                            
                            <p>We may collect and use the following data for marketing purposes: your picture, name, video and audio recordings of you, your written statements, a description of your business, your current loan and previous loan statuses,  and other general information about you.<br /><br /></p>
                            
                            <p>Dushobora gukusanya no gukoresha amakuru akurikira mu bikorwa byo kwamamaza: ifoto yawe, izina, videwo n'amajwi yawe, amagambo yawe mu nyandiko, ibisobanuro by’ubucuruzi bwawe, umwenda/ideni ufite n’iyo wigeze gufata muri BioMassters, hamwe n’andi makuru rusange akwerekeye.<br /><br /></p>
                            
                            <h3>1.5. Newsletter<br />1.5. Inyandiko y’Amakuru<br /><br /></h3>
                            
                            <p>We may collect and use the following data to send you our newsletter: name, email address, employer.<br /><br /></p>
                            
                            
                            <p>Dushobora gukusanya no gukoresha amakuru akurikira kugirango twohereze Inyandiko y’Amakuru yacu: izina, aderesi imeri, umukoresha.<br /><br /></p>

                            <h3>1.6. Website<br />1.6. Website<br /><br /></h3>
                            
                            <p>On our website we make use of third-party cookies by YouTube and Soundcloud.<br /><br /></p>
                            
                            
                            <p>Kuri website wacu dukoresha amakuru  y'urundi ruhande hakoreshejwe YouTube and Soundcloud.<br /><br /></p>
        
                            <h2>2. Storage of your data</h2>
                            
                            <h3>2.  Ibikwa ry’amakuru yawe</h3>
                            
                            <p>Your data will be stored on a protected AMS server and on the servers of our partners mentioned in section 1. Only employees of BioMassters Ltd., and selected partners as described in section 1 have access to this data.<br /><br />We keep your personal data stored for as long as you are making use of our services and 5 years beyond that. You have the right to request that your data be anonymised from our records, however this also means a cessation of access to the associated BioMassters services and this does not affect the lawfulness of the processing of your personal data based on consent before its withdrawal. For PAYGO customers, this means that any outstanding repayments at the date of the request must be settled immediately in conjunction with  termination of the PAYGO contract. Please note that BioMassters will only remove the data we are not legally obliged to retain.<br /><br /></p>
                            
                            <p>Amakuru yawe azabikwa mububiko AMS bwizewe ndetse no mu bubiko bw’abafatanyabikorwa bacu twavuze haruguru mugika cya 1. Abakozi bonyine ba Biomassters n’abandi bafatanyabikorwa batoranijwe nkuko byasobanuwe mu gika cya 1 nibo bonyine babona amakuru yawe.<br /><br />Tubika amakuru yawe y’ibanga igihe cyose ugikoresha serivisi zacu mu gihe cy’imyaka 5 kuzamura. Ufite uburenganzira bwo gusaba amakuru yawe agasibwa mu nyandiko zacu ariko bikaba binasobanuye ko utakiri umukiliya wa Biomassters utacyemerewe na serivisi zacu. Ibi nta cyo bihindura ku itegeko ry’itunganywa ry’amakuru yawe bwite dukurikije k’uburenganzira watanze mbere yo kwisubiraho. Ku bakiliya bafashe ishyiga ku ideni (PAYGO) bisobanuyeko bo babanza bakamara kwishyura cyangwa tugasesa amasezerano . Menyako BioMassters izasiba amakuru tudategetswe nitegeko kugumana yonyine.<br /><br /></p>
                            
                            <h2>3. Your rights with regards to your personal data</h2>
                            
                            <h3>3 . Uburenganzira bwawe kubyerekeye amakuru yawe bwite</h3>
                            
                            <p>If you have any questions or would like to know which personal data we have stored, you can contact us during office hours via our business communication channels. We will endeavor to respond to your request within 2 weeks. You have the right to:<br /><br /></p>
                            <li>an explanation about the aforementioned  personal data and how we use it</li>
                            <li>request to see all personal data we have stored of you</li>
                            <li>have errors in your personal data corrected</li>
                            <li>delete personal data which is out of date</li>
                            <li>revoke your permission for saving your data by BioMassters Ltd. (with the consequence of no longer being able to enjoy the associated services) </li>
                            <li>object to the use of certain data (some services may not be available anymore, depending on the type of data to be rejected for use) </li>
                            <li>transfer your data to a third party</li>
                            <li>designate an heir to your personal data</li>
                            <li>be represented by another regarding your data in case of illness or impairment</li>
                            
                            <p><br /> <br />Igihe wagira ikibazo icyo ari cyo cyose ku makuru yawe bwite tubitse, waduhamagara mumasaha y’akazi mukoresheje imirongo twabahaye yacu. Tuzabaha igisubizo mubyumweru 2 . Ufite uburenganzira bwo:<br /><br /></p>
                            <ul>
                            <li>Ubusobanuro bujyanye n’amakuru bwite twavuze n’uko akoreshwa</li>
                            <li>Gusaba kuba wareba amakuru bwite yawe yose dufite</li>
                            <li>Amakosa ari mu makuru yawe bwite gukosorwa</li>
                            <li>Gusiba amakuru yawe bwite yarangijwe gukoreshwa</li>
                            <li>Kwisubiza uburenganzira wari waratanze bwo kubika amakuru yawe bwite na Biomassters Ltd (byavamo ko waba ntaho ugihurira na serivisi zacu zose) </li>
                            <li>Kwanga ko hari amakuru yawe rukana yakoreshwa (ibi bikaba byatuma hari serivisi utakongera kubona bite we n’amakuru wanze ko yakoreshwa) </li>
                            <li>Kuba amakuru yawe yakoherezwa kubandi babishinzwe</li>
                            <li>Kuba wakishyiriraho undi muntu wasangizwa amakuru yawe bwite</li>
                            <li>Kuba wahagararirwa n’undi kubyerekeye amakuru yawe bwite mu gihe utabashije kuboneka cyangwa urwaye</li>
                            </ul>
                            
                            <h3><br />Contact information for this privacy notice<br />Ku bindi bisobanuro ku bijyanye n’iyi fishi wahamagara<br /><br /></h3>
                            
                            <p>info@biomassters.co<br /></p>
                            <p>Call Center telephone number: +250 786 424 209<br /><br /></p>
                            
                            <p>René van der Velden, Data Protection Officer<br /></p>
                            <p>renevandervelden@biomassters.co<br /><br /></p>


        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
