import './App.css';
//import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import PrivacyPolicy from './components/pages/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element = {<Home />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
