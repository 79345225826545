export const ressourcedata=[
    {
        MainContent:"An excellent podcast with Michael Shepard, founder of Heza, who explains in great depth the enormous opportunity for pellet cooking and how to get there.",
        img:"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1043833537&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
        url:'',
        link:false,
        btntext:'Listen',
        bool:false,
        Videos:true
    },
    {
        MainContent:'Independent academic research on the performance of the pellet + gasifier stove combination in Rwanda.',
        img:'./Images/Team/Analysis.gif',
        url:'https://pubs.acs.org/doi/10.1021/acs.est.9b00009',
        link:false,
        btntext:'Read more',
        bool:true,
        Videos:false
    },
    {
        MainContent:`Inyenyeri and Today's Biomass Pellet Pionners By Ronan Ferguson, Senior Manager, Private Sector & Investment, Clean Cooking Alliance.`,
        img:'https://eus-www.sway-cdn.com/s/xTKYvj0ul9zDC4uV/images/G9UN1Fq0har2hC?quality=3184&allowAnimation=true&embeddedHost=true',
        url:'https://sway.office.com/s/xTKYvj0ul9zDC4uV/embed',
        link:false,
        btntext:'Read more',
        bool:true,
        Videos:false
    },
    {
        MainContent:`Rwandan national TV channel KC2 recently aired a 5-minute item on BioMassters. Did you miss it?,
        `,
        img:'./Images/Team/KC2.jpg',
        url:'https://www.youtube.com/embed/qI_BmqRxYnA',
        link:true,
        btntext:'Watch it here.',
        bool:false,
        Videos:false
    },
    {
        MainContent:`A new CCA report with a 'Call to Action': Accelerating clean cooking as a nature-based climate solution.`,
        img:'./Images/Team/Woman.png',
        url:'./Images/Team/Accelerating-Clean-Cooking-as-a-Nature-Based-Climate-Solution.pdf',
        link:false,
        btntext:'Read more',
        bool:true,
        Videos:false
    },
    {
        MainContent:`Groundbreaking study in Nature shows that pellet cooking offers best value for money, i.e. the most affordable option among the very few "Pro-Health" and "Pro-Climate" cooking solutions.`,
        img:'./Images/Team/Health.png',
        url:'https://www.nature.com/articles/s41560-022-01126-2',
        link:false,
        btntext:'Read more',
        bool:true,
        Videos:false
    }
   
]
