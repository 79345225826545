import React from 'react'
import {ParnersLogo} from '../data/Partnersdata'
import '../css/Partners.css'
function Partners() {
    return (
        <div className="PartnersContainers" id="PartnersID">
            <div className="Svg">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
            </div>
            <div className="Container Logos">
                <div className="LogosTiles"><h2>Partners</h2></div>
                <div className="LogoBoxes">
                    {
                        ParnersLogo.map((el, index)=>{
                            const{Logoname, LogoImage, url}=el;
                            return(
                                <div className="Box" key={index}>
                                    <a href={url}><img src={LogoImage} alt={Logoname}></img></a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Partners
