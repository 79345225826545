import React, {useState} from 'react'
import {Teamdata, BoardTeam} from '../data/Teamdata'
import Model from './Model'
import ModelMember from './ModelMember'
//import { Link } from 'react-router-dom'
import '../css/Team.css'

function Team() {
    const[feedIndex, SetFeedIndex]=useState();
    const[boardfeedIndex, SetBoardFeedIndex]=useState();
    const [ShowModels, SetShowModels]=useState(false);
    const [BoardShowModels, SetBoardShowModels]=useState(false);

const ShowModel=(ev)=>{
   SetFeedIndex(ev.target.getAttribute('id'));
   SetShowModels(true)
}
const ShowModelBoard=(ev)=>{
    SetBoardFeedIndex(ev.target.getAttribute('id'));
   SetBoardShowModels(true)
}


    return (
        <div className="TeamContainers" id="TeamID">
            <div className="Svg">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="white"></path></svg>
            </div>
                <div className=" Container TitlesTeam"><h2>Meet our Team</h2></div>
            <div className="Container BiomastersTeam">
            
                {
                    Teamdata.map((el,index)=>{
                        const {Id,FirstName, LastName,Title, urlLinkidin, urlTeam}=el;
                        return(
                        <div className="TeamBox" key={index}>
                            <div className="BannersBox">
                                <div className="BannerBackgrounds"></div>
                                <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
                            </div>
                            <div className="ImageBoxes">
                                <div className="ImageMain"><img src={urlTeam} alt={urlTeam}></img></div>
                            </div>
                            <div className="TeamInfos">
                                <div className="Names">
                                    <h2>{FirstName}</h2>
                                    <p>{LastName}</p>
                                </div>
                               
                                <p className="TitlesCss">{Title}</p>
                            </div>
                            <div className="SocialInfos">
                                <div className="ReadMores" id={Id} onClick={ShowModel}><i className="fas fa-info"></i> <span>Read more about {FirstName}</span></div>
                                <a href={urlLinkidin}><i className="fab fa-linkedin"></i></a>
                                
                                </div>
                        </div>
                        )
                    })
                    
                }
            </div>
            <div className="Container BoardMembers">
            {
                   
                   BoardTeam.map((el,index)=>{
                    const {Id,FirstName, LastName,Title, urlLinkidin, urlTeam, BioBool}=el;
                    return(
                    <div className="TeamBox" key={index}>
                        <div className="BannersBox">
                            <div className="BannerBackgrounds"></div>
                            <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
                        </div>
                        <div className="ImageBoxes">
                            <div className="ImageMain"><img src={urlTeam} alt={urlTeam}></img></div>
                        </div>
                        <div className="TeamInfos">
                            <div className="Names">
                                <h2>{FirstName}</h2>
                                <p>{LastName}</p>
                            </div>
                           
                            <p className="TitlesCss">{Title}</p>
                        </div>
                        <div className="SocialInfos">
                        {BioBool?<div className="ReadMores" id={Id} onClick={ShowModelBoard}><i className="fas fa-info"></i> <span>Read more about {FirstName}</span></div>:null}
                                <a href={urlLinkidin}><i className="fab fa-linkedin"></i></a>
                            
                        </div>
                    </div>
                    )
                })
                }
            </div>
            {ShowModels?<Model feedIndex={feedIndex} ModelOffoload={SetShowModels} />:null}
            {BoardShowModels?<ModelMember boardfeedIndex={boardfeedIndex} ModelMemberOffoload={SetBoardShowModels} />:null}
        </div>
    )
}

export default Team
