import React from 'react'
import '../css/About.css'
import {mission} from '../data/Missiondata'
import {aboutdata} from '../data/Aboutdata'

function About() {
    const {mainContent,  Img}=aboutdata[0];

    return (
        <div className="AboutContainers" id="AboutID">
            <div className="Aboutshadows">
            <div className="Container AboutMain">
                <div className="AboutTitles"><h2>About us</h2></div>
                <div className='About_Session'>
                    <div className="AboutContente">
                        <div className="AboutText"><span>{mainContent}</span></div>
                    </div>
                    <div className="BioPhoto">
                        <div className='BioImage'>
                        <div className="img"><img src={Img} alt={Img}></img></div>
                        </div>
                        
                    </div>
                </div>
                <div className="GoalsContent">
                    <div className="BoxVisions">
                    {
                        mission.map((el, index)=>{
                            const{content, Title}=el;
                            return(
                                <div className="boxes" key={index}>
                                        <div className="BoxTitles"><h3>{Title}</h3></div>
                                        <div className="BoxContents">
                                            <p>{content}</p> </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default About
