import React from 'react'
import '../css/Problem.css'
import {MainData, SubPhoto} from '../data/Problemdata'

function Problem() {
    const {MainContent}=MainData[0]
    return (
        <div className="ProblemContainer" id="ProblemID">
            <div className="SvgContainers">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
            </div>
            <div className=" Container ProblemMain">
                <div className='H2_Problems'><h2>Problem</h2></div>
                <div className='Problem_sessions'>
                    <div className="ProblemPhoto">
                        <div className="ProblemSubPhoto">
                        {
                            SubPhoto.map((el, index)=>{
                                const {img}=el;
                                return(
                                    <div className="SubBox" key={index}>
                                        <div className="imgbox"><img src={img} alt={img}></img></div>
                                    { /*<span>{textInfos}</span>*/}
                                    </div>
                                )
                            })
                        }

                        </div>
                    </div>
                    <div className="ProblemText">
                        <div className='MainProblemText'><p>{MainContent}</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Problem
