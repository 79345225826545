import React from 'react'
import '../css/Model.css'
import {BoardTeam} from '../data/Teamdata'

function ModelMember({boardfeedIndex, ModelMemberOffoload}) {
    const{FirstName, LastName,  Title, urlTeam, Bios}=BoardTeam[boardfeedIndex];
    return (
        <div className="ModelContainers">
            <div className="ModelOvelay" onClick={()=>ModelMemberOffoload(false)}></div>
            <div className="ProfileInfos">
               <div className="Infos">
                <div className="closeTimes"onClick={()=>ModelMemberOffoload(false)}><i className="fas fa-times"></i></div>
                <div className="IfosContainers">
                    <div className="TopBanner">
                        <div className="ProfPhoto"><img src={urlTeam} alt={urlTeam}></img></div>
                       
                    </div>
                     <div className="svgContainerss"> 
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
                     </div>
                </div>
                <div className="BiosContent">
                        <div className="BiosTitles">
                            <h2>{FirstName}  {LastName}</h2>
                            <span>{Title}</span>
                            <div className="Lines"></div>
                        </div>

                    <div className='Biograph_infos'><p>{Bios}</p></div>
                </div>
               </div>
            </div>
        </div>
    )
}

export default ModelMember
