import React from 'react'
import {ressourcedata} from '../data/ressourcedata'



import '../css/Resource.css'
 const srv="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1043833537&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false&no_preview=true";
function Resource() {
   
    return (
        <div className="ResourcesContainer" id="ResourcesID">
             <div className="SvgContainers">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" fill="#f0f2f5"></path></svg>
            </div>
            <div className=" Container TitlesTeam"><h2>Resources</h2></div>
           {/*<VideoPlayer src={VideoBiomaster}></VideoPlayer>*/} 
         
            <div className=" Container ResourceMain">
                
             
                {
                    ressourcedata.map((el, index)=>{
                        const{MainContent, img, url, btntext, bool,Videos,  link}=el;
                        return(
                            <div className="RessourceBox" key={index}>
                                <div className="RessourceImage">
                                    {
                                    bool? <img src={img}alt={img}></img>:<iframe src={Videos?srv:url} title={url}></iframe> 
                                
                                    
                                    }
                                </div>
                                <div className="RessourceInfos">
                                    <span>{MainContent}  { link?<a className="videosLink"href='https://www.youtube-nocookie.com/watch?v=qI_BmqRxYnA'>Watch it here.</a>:null}</span>
                                    <div className="RessourceBtn">
                                    {bool?<a href={url}>{btntext}</a>:null}
                                    </div>
                                </div>
                               
                            </div>
                        )
                    })
                }
           
               
               
            </div>
            
        </div>
    )
}

export default Resource
