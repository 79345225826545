export const solutiondata=[
    {
        MainContent:'BioMassters aims to make charcoal production and consumption redundant in Rwanda. We do this by supplying pellets as cooking fuel and by creating the demand for these pellets through the distribution of the most advanced biomass gasification stoves on the global market. Pellets are a homogenous fuel with little variability in size, shape and moisture content that is inexpensive to produce and transport. In combination with Tier-4 gasification stoves, pellets provide a flame nearly as clean and convenient as LPG for a price significantly below charcoal. BioMassters offers a ‘Lease-to-Own’ stove subscription contract to its customers. Our PAYGO model removes the upfront risk and cost to our customers when switching to our solution and ensures savings from day one. In addition to the benefits for the customer, there are huge benefits to the public as well. Pellet burning is climate- and environmentally friendly, relieving the pressure on Rwandan forests from charcoal production by reducing biomass use by up to 90%. Moreover, BioMassters uses mostly biomass waste that is left over from forest management, reforestation and furniture production as we strive for making the circular economy a reality in Rwanda.',
        Img:'./Images/Team/MummyCooking.jpg'
    }
]

export const solutionCard=[
    {
        Icon:'fas fa-walking',
        Content:'Easy to join: No upfront stove purchase but Pay-As-You-Go cooking '
    },
    {
        Icon:'fas fa-clock',
        Content:'Fast and convenient: Start cooking in just 1-2 minutes. '
    },
    {
        Icon:'fas fa-coins',
        Content:'Affordable: Customers save as much as 30% compared to dirty charcoal. '
    },
    {
        Icon:'fas fa-tree',
        Content:'Climate- and environmentally friendly: Reduces biomass use up to 90% and slashes emissions  '
    }
]