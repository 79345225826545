export const mission=[

    // Mission -------------------------------------------------------------------
    {
        content:'We aim to replace charcoal production and charcoal cooking by providing modern, affordable and low-carbon pellet fuel production and cooking solutions, based on locally produced fuel from waste materials.',
        Title:'Mission'
       
    },
    
    // Vission -------------------------------------------------------------------
    {
        content:'We strive to develop a low-carbon pellet cooking value chain by pioneering and scaling the most customer-centric, climate friendly and financially sustainable cooking company in Africa.',
        Title:'Vision'
    }
]
