import React, {useState, useEffect} from 'react'
import {Link} from 'react-scroll'
import {MenuItem}  from '../data/MenuItem'
import '../css/NavBar.css'
function NavBar() {
    const [ShowMenu, SetShowMenu]=useState(false);

    useEffect(()=>{
        const WinwowWidth=()=>{
            if(window.innerWidth >960){
                SetShowMenu(false);
                
            }
        }
        window.addEventListener("resize", WinwowWidth);
        return (()=>{
            window.removeEventListener("resize", WinwowWidth);
        })
    },[])
    
   
   
    
    return (
        <div className="NavSide">
            <nav className="Nav">
                <div className="Container NavMenu">
                    <Link to="BannerID"><div className="Logo"onClick={()=>SetShowMenu(false)}><div className="ImgLogo"><img src="./Images/Logos/Icon.svg" alt="ImgLogo"></img><span className="logoTitle"><b>Bio</b>Massters</span></div></div></Link>
                    <div className={ShowMenu? "NavitemActive" :"Navitem"}onClick={()=>SetShowMenu(false)}>
                        {MenuItem.map((el,index)=>{const {url, item}=el;return(<div  key={index} className="ListItem">
                            <Link onClick={()=>SetShowMenu(false)} activeClass="active" to={url} spy={true} smooth={true} offset={-90} duration={500} exact="true">{item}</Link>
                            </div> )})}
                    </div>
                    <div className="BarIcon" onClick={()=>SetShowMenu(!ShowMenu)}><i className={ShowMenu? 'fas fa-times' : 'fas fa-bars'}></i></div>
                    
                </div>
            </nav>
            
           
        </div>
    )
}

export default NavBar
