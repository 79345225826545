import React,{useState, useEffect } from 'react';
import '../css/Banner.css';
import {slide} from '../data/Bannerdata';

function Banner() {
    const [index, SetIndex]=useState(0);

    useEffect(()=>{
        const lastIndex=slide.length -1;
        if(index<0){
            SetIndex(lastIndex)
        }
        if(index>lastIndex){
            SetIndex(0)
        }
       },[index])
       
       useEffect(()=>{
           let slider=setInterval(()=>{
            SetIndex(index+1)
           },4000);
           return(()=>clearInterval(slider));
       },[index])
    return (
        <div className="BannerContaines" id="BannerID">
            <div className="Ovelrays">
                <div className="IconsDirect"><i className="fas fa-caret-left" onClick={()=>SetIndex(index -1)}></i><i className="fas fa-caret-right"onClick={()=>SetIndex(index +1)}></i></div>
            </div>
            {
                slide.map((el, BannerIndex)=>{
                  
                    const {Title, MainContent, img, slogan}= el;
                    let position="NextSlide";
                    if(BannerIndex === index){
                        position="ActiveSlide"
                    }
                    if(BannerIndex === index -1 || (index === 0 && BannerIndex === slide.length -1)){
                        position="LastSlide"
                    }

                    return(
                        <section className={position} key={BannerIndex}>
                            <div className="Imgbag">{<img src={img} alt={img}></img>}</div>
                            <div className="Contente">
                                <div className="Container BannerMainContent">
                                    <div className="DummSpan"></div>
                                    <div className="LeftContent">
                                        <span className="BannersTitle">{Title}</span>
                                        <span className="Slogann"><p>{slogan}</p></span>
                                        <span className="BannerContente">{MainContent}</span>
                                    </div>
                                </div>
                            </div>
                         </section>
                    )
                })
               
            }
           
            {/*SVG------------------------------------------ Bottoms*/}
            <div className="SvgContainer">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" className="Svg"><path d="M0.00,49.99 C150.00,150.00 349.21,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" fill="#f0f2f5"></path></svg>
            </div>
        </div>
    )
}

export default Banner
