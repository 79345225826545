export const ParnersLogo=[
    {
        Logoname:'Mimi Moto',
        LogoImage:'./Images/Logos/Mimi Moto.jpg',
        url:'https://mimimoto.nl/'
    },
    {
        Logoname:'Clean cooking C',
        LogoImage:'./Images/Logos/Clean cooking C.jpg',
        url:'https://www.cleancookingalliance.org/'
    },
    {
        Logoname:'get investment Photo C',
        LogoImage:'./Images/Logos/get investment Photo C.jpg',
        url:'https://www.get-invest.eu/'
    },
    {
        Logoname:'Fair Climate C',
        LogoImage:'./Images/Logos/Fair Climate C.jpg',
        url:'https://www.fairclimatefund.nl/en'
    },
    {
        Logoname:'GFA C',
        LogoImage:'./Images/Logos/GFA C.jpg',
        url:'https://www.gfa-group.de/index.html'
    },
    {
        Logoname:'Heza C',
        LogoImage:'./Images/Logos/Heza C.jpg',
        url:'https://www.hezaearth.org/'
    },
    {
        Logoname:'Michigan C',
        LogoImage:'./Images/Logos/Michigan C.jpg',
        url:'https://michigan.law.umich.edu/'
    },
    {
        Logoname:'Santa barbara C',
        LogoImage:'./Images/Logos/Santa barbara C.jpg',
        url:'https://www.ucsb.edu/'
    },
    {
        Logoname:'Seal C',
        LogoImage:'./Images/Logos/Seal C.jpg',
        url:'https://www.seal.rw/'
    },
    {
        Logoname:'EDCL Photo',
        LogoImage:'./Images/Logos/EDCL Photo.jpg',
        url:'https://www.reg.rw/'
    },
    {
        Logoname:'FPNA C',
        LogoImage:'./Images/Logos/FPNA C.jpg',
        url:'http://pfan.net/'
    },
    {
        Logoname:'Nethland Logo',
        LogoImage:'./Images/Logos/Nethland Logo.png',
        url:'https://english.rvo.nl/'
    },
    {
        Logoname:'LendwithCare',
        LogoImage:'./Images/Logos/og-lwc-logo.webp',
        url:'https://lendwithcare.org/'
    },
    
    {
        Logoname:'CharmLogo',
        LogoImage:'./Images/Logos/Charm-primary-logo.png',
        url:'https://charmimpact.com/investment-campaigns/clean-cooking-with-biomassters-1-details'
    },
    {
        Logoname:'Ospreylogo',
        LogoImage:'./Images/Logos/Ospreylogo.png',
        url:'http://www.ospreyfdn.org/'
    }
   
]