import React, {useState} from 'react'
//import {Formik, Form, Field, ErrorMessage} from 'formik'
import '../css/Contact.css'
import * as Yup from 'yup'
//import ContactForm from './ContactForm';
import axios from 'axios';

/*Forms number 1---------------------------------------------------------*/

function Contact() {
    const [Msg, SetMsg]=useState();
    const [msgBool, SetMsgBool]=useState(false)
    const validationSchema= Yup.object({
        emails: Yup.string().email('Invalid email').required('Required !')
    });
    const NowYear=new Date().getFullYear();
    const initialValues={
        emails: ''
    }
    const onSubmit= (values, onSubmitProps) =>{
        const email=values.emails;
        axios.post("https://biomassters.herokuapp.com/subscribe",{email:email})
        .then(response =>{
            SetMsg(response.data);
            SetMsgBool(true);
    
            setTimeout(()=>{
                SetMsgBool(false);
            },4000)
            onSubmitProps.resetForm();
        })
        .catch(error =>{
            //console.log('Network Problems')
        })
    
        onSubmitProps.setSubmitting(false)
        
    }
    return (
        <div className="ContactContainer" id="ContactID">
            
            <div className="ContactSvgContainers">
             <svg viewBox="0 0 500 150" preserveAspectRatio="none" ><path d="M0.00,49.99 C150.00,150.00 349.21,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z" fill="#32704a"></path></svg>
            </div>
            <div className='ContainerContactNew'>
                
                <div className="ContactUs">
                    <div className="ContactBox">
                        <h3><i className="fas fa-envelope-open"></i>Email</h3>
                        <div className="ContactInfos">
                            <p>Info@biomassters.co</p>
                        </div>
                    </div>
                        <div className="ContactBox">
                            <h3><i className="fas fa-map-marked-alt"></i>Address</h3>
                            <div className="ContactInfos">
                                <p>Rwezamenyo Sector</p>
                                <p>Rwezamenyo Cell</p>
                                <p>KN 2 AV 304 ST</p>
                                <p>Kigali</p>
                                <div className='Social_Icons'><a href="https://www.linkedin.com/company/biomassters/"> <i className="fab fa-linkedin "></i></a></div>
                            </div>
                          
                        </div>
                        <div className="ContactBox">
                             <h3><i className="far fa-newspaper"></i>Newsletter</h3>
                            <div className="ContactInfos">
                                <span>Subscribe to our newsletter for regular updates</span> <br></br>
                                <a href='https://biomassters.us7.list-manage.com/subscribe?u=301d5451dd69f5beaa43117ae&id=727c93bd64' ><div className='Subscribe_btns'>Subscribe</div></a>
                        
                                <h3><a href="/PrivacyPolicy" target="_blank" rel="noreferrer">Privacy Policy</a></h3>

                            {/*<Formik initialValues={initialValues}  onSubmit={onSubmit} validationSchema={validationSchema} >
                                {formik =>{
                                 
                                    return(
                                        <Form autoComplete="off">
                                            <div className="btnText">
                                            <Field type="email" name="emails" placeholder="Your email"></Field>
                                            <button className='Subscribe_btn' type="submit" disabled={!formik.isValid || formik.isSubmitting} >Subscribe</button>
                                            </div>
                                            <ErrorMessage name="emails">
                                                {(errorMSg)=><span className="errors">{errorMSg}</span>}
                                            </ErrorMessage>
                                            {msgBool? <h4 className="SuccessMeaage">{Msg}</h4>:null}
                                         </Form>
                                    )
                                }}
                            
                            </Formik>*/}
                            </div>
                        </div>

                </div>

            </div>
            <div className="Copyright">
                <span>{`@${NowYear} BioMassters, All Rights Reserved`}</span>
            </div>


        </div>
    )
}

export default Contact
