export const slide=[
    {
        Title:'BioMassters',
        MainContent:'Find out what makes us great for customers and the planet',
        img:'./Images/Team/OptimizeImage/MainBacground.jpg',
        slogan:'The Clean Cooking Solution'
    },
    {
        Title:'BioMassters',
        MainContent:'Find out what makes us great for customers and the planet',
        img:'./Images/Team/OptimizeImage/_Final-Hero2.jpg',
        slogan:'The Clean Cooking Solution'
    },
    {
        Title:'BioMassters',
        MainContent:'Find out what makes us great for customers and the planet',
        img:'./Images/Team/OptimizeImage/Environment.jpg',
        slogan:'The Clean Cooking Solution'
    }
]