export const MainData=[
    {
        MainContent:'2.8 billion people globally do not have access to modern energy cooking services, including the majority of Rwandan households. This is deeply problematic, since traditional cooking is energy inefficient, highly polluting and thus harmful to people and the planet alike. In Rwanda, traditional cooking is estimated to be responsible for 40% of the country’s air pollution. Globally, cooking with traditional non-renewable fuel wood is responsible for 1.9-2.3% of all CO2 emissions. Burning residual solid fuels for cooking is also responsible for 58% of global black carbon emissions, a potent short-lived greenhouse gas. Moreover, the fumes from traditional cooking are known to cause a health tragedy. It is responsible for 3.8 million premature deaths per year globally, of which more than 7,000 in Rwanda. According to a World Bank report published in 2016 "exposure to air pollution is now the fourth leading fatal health risk worldwide behind metabolic risks, dietary risks, and tobacco smoke. More than six times as many people die from air pollution each year as from malaria, and more than four times as many die from air pollution as from HIV/AIDS”. Altogether, the global cost of inaction is estimated to be 2.4 trillion dollars per year. While traditional fuels, like charcoal, are expensive and dirty, very few alternatives are available to date.',
        ItsPhoto:'./Images/Team/ToDo.jpg'
    }
]

export const SubPhoto=[
    {
        img:'./Images/Team/ToDo.jpg',
        //textInfos:'Traditional cooking is energy inefficient, highly polluting and thus harmful to people and the planet alike.'
    },
    {
        img:'./Images/Team/forest.jpeg',
        //textInfos:'Burning residual solid fuels for cooking is also responsible for 58% of global black carbon emissions'
    },   /*
    {
        img:'./Images/Team/_Final_1.jpg',
        textInfos:'Burning residual solid fuels for cooking is also responsible for 58% of global black carbon emissions'
    },
 
    {
        img:'./Images/Team/ToDo.jpg',
        textInfos:'Deforestations always have huge impact on environment'
    }*/
]